export default [
  {
    name: 'unique_id',
    displayName: 'Project ID',
    type: 'string',
    isCheckbox: true,
  },
  {
    name: 'dealer',
    displayName: 'Sales Partner',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'rep1',
    displayName: 'Rep 1',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'home_owner',
    displayName: 'Home Owner',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'contract_date',
    displayName: 'Contract Date',
    type: 'date',
    isCheckbox: false,
  },
  {
    name: 'contract_value',
    displayName: 'Contract Value',
    type: 'number',
    isCheckbox: false,
  },
  { name: 'amount', displayName: 'Amount', type: 'number', isCheckbox: false },
  {
    name: 'amt_paid',
    displayName: 'Amount Paid',
    type: 'number',
    isCheckbox: false,
  },
  {
    name: 'balance',
    displayName: 'Balance',
    type: 'number',
    isCheckbox: false,
  },
  { name: 'credit', displayName: 'Credit', type: 'number', isCheckbox: false },
  { name: 'epc', displayName: 'EPC', type: 'number', isCheckbox: false },
  {
    name: 'net_epc',
    displayName: 'NET EPC',
    type: 'number',
    isCheckbox: false,
  },
  {
    name: 'net_rev',
    displayName: 'NET REV',
    type: 'number',
    isCheckbox: false,
  },
  {
    name: 'current_status',
    displayName: 'Current Status',
    type: 'string',
    isCheckbox: false,
  },
  { name: 'state', displayName: 'State', type: 'string', isCheckbox: false },
  { name: 'dba', displayName: 'Dba', type: 'string', isCheckbox: false },
  {
    name: 'status_date',
    displayName: 'Status Date',
    type: 'date',
    isCheckbox: false,
  },
  {
    name: 'sys_size',
    displayName: 'Sys Size',
    type: 'number',
    isCheckbox: false,
  },
  {
    name: 'loan_fee',
    displayName: 'Loan Fee',
    type: 'number',
    isCheckbox: false,
  },
  {
    name: 'draw_amt',
    displayName: 'Draw Amt',
    type: 'number',
    isCheckbox: false,
  },
  { name: 'rl', displayName: 'RL', type: 'number', isCheckbox: false },
  {
    name: 'OtherAdders',
    displayName: 'Other Adders',
    type: 'number',
    isCheckbox: false,
  },
  { name: 'rep2', displayName: 'Rep 2', type: 'string', isCheckbox: false },
  { name: 'help', displayName: 'Help', type: 'string', isCheckbox: false },
];
