import React from 'react';

const UserIcon = ({ color = '#E0728C' }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="8" fill={color} />
    <path
      d="M16 14.6001C18.2091 14.6001 20 12.8092 20 10.6001C20 8.39096 18.2091 6.6001 16 6.6001C13.7909 6.6001 12 8.39096 12 10.6001C12 12.8092 13.7909 14.6001 16 14.6001Z"
      fill="white"
    />
    <path
      d="M16.8481 15.6001H15.1521C14.1572 15.6032 13.1727 15.8023 12.2547 16.186C11.3368 16.5696 10.5034 17.1303 9.80211 17.836C9.10084 18.5417 8.54545 19.3787 8.16763 20.2991C7.78981 21.2194 7.59698 22.2052 7.60014 23.2001C7.60014 23.3062 7.64228 23.4079 7.71729 23.4829C7.79231 23.558 7.89405 23.6001 8.00014 23.6001H24.0001C24.1112 23.59 24.2142 23.5381 24.2885 23.455C24.3627 23.3718 24.4026 23.2636 24.4001 23.1521C24.3959 21.1505 23.5989 19.2321 22.1835 17.8167C20.7682 16.4013 18.8497 15.6043 16.8481 15.6001Z"
      fill="white"
    />
  </svg>
);

export default UserIcon;
