import { Column } from '../../../core/models/data_models/FilterSelectModel';

// export const RebeteDataColumn: Column[] = [
//   {
//     name: 'unique_id',
//     displayName: 'Unique ID',
//     type: 'string',
//     isCheckbox: true,
//   },
//   {
//     name: 'cutomer_ver',
//     displayName: 'Cust Ver',
//     type: 'string',
//     isCheckbox: false,
//   },

//   { name: 'type', displayName: 'Type', type: 'string', isCheckbox: false },
//   { name: 'item', displayName: 'Item', type: 'string', isCheckbox: false },
//   { name: 'amt', displayName: 'Amount', type: 'string', isCheckbox: false },
//   { name: 'rep', displayName: 'Rep $ / %', type: 'string', isCheckbox: false },
//   { name: 'notes', displayName: 'Notes', type: 'string', isCheckbox: false },
//   { name: 'rep_1', displayName: 'Rep1', type: 'string', isCheckbox: false },
//   { name: 'rep_2', displayName: 'rep_2', type: 'string', isCheckbox: false },
//   {
//     name: 'sys_size',
//     displayName: 'Sys Size',
//     type: 'string',
//     isCheckbox: false,
//   },
//   { name: 'state', displayName: 'State', type: 'string', isCheckbox: false },
//   {
//     name: 'rep_count',
//     displayName: 'Rep Count',
//     type: 'string',
//     isCheckbox: false,
//   },
//   {
//     name: 'per_rep_addr',
//     displayName: 'Per Rep Addr',
//     type: 'string',
//     isCheckbox: false,
//   },
//   {
//     name: 'per_rep_def_ovrd',
//     displayName: 'Per Rep Def Ovrd',
//     type: 'string',
//     isCheckbox: false,
//   },
//   {
//     name: 'per_rep_ovrd',
//     displayName: 'Per Rep Ovrd',
//     type: 'string',
//     isCheckbox: false,
//   },
//   { name: 'share', displayName: 'Share', type: 'string', isCheckbox: false },
//   {
//     name: 'r1_pay_scale',
//     displayName: 'R1 Pay Scale',
//     type: 'string',
//     isCheckbox: false,
//   },
//   {
//     name: 'r1_rebet_credit$',
//     displayName: 'R1 Rebete Credit $',
//     type: 'string',
//     isCheckbox: false,
//   },
//   {
//     name: 'r1_rebet_credit%',
//     displayName: 'R1 Rebete Credit %',
//     type: 'string',
//     isCheckbox: false,
//   },
//   {
//     name: 'r1_addr_resp',
//     displayName: 'R1 Addr Resp',
//     type: 'string',
//     isCheckbox: false,
//   },
//   {
//     name: 'r2_pay_scale',
//     displayName: 'R2 Pay Scale',
//     type: 'string',
//     isCheckbox: false,
//   },
//   {
//     name: 'r2_rebet_credit$',
//     displayName: 'R1 Rebete Credit $',
//     type: 'string',
//     isCheckbox: false,
//   },
//   {
//     name: 'r2_rebet_credit%',
//     displayName: 'R2 Rebete Credit %',
//     type: 'string',
//     isCheckbox: false,
//   },
//   {
//     name: 'rep2_def_resp',
//     displayName: 'Rep 2 Def Resp',
//     type: 'string',
//     isCheckbox: false,
//   },
//   {
//     name: 'r2_addr_resp',
//     displayName: 'R2 Addr Resp',
//     type: 'string',
//     isCheckbox: false,
//   },
//   {
//     name: 'start_date',
//     displayName: 'Start date',
//     type: 'string',
//     isCheckbox: false,
//   },
//   { name: 'end_date', displayName: 'End Date', type: 'string', isCheckbox: false },
// ];

export const RebeteDataColumn: Column[] = [
  {
    name: 'unique_id',
    displayName: 'Unique ID',
    type: 'string',
    isCheckbox: true,
  },
  {
    name: 'customer_verf',
    displayName: 'Cust Ver',
    type: 'string',
    isCheckbox: false,
  },
  { name: 'type', displayName: 'Type', type: 'string', isCheckbox: false },
  {
    name: 'type_rd_mktg',
    displayName: 'Type Rd Mkt',
    type: 'string',
    isCheckbox: false,
  },
  { name: 'item', displayName: 'Item', type: 'string', isCheckbox: false },
  { name: 'amount', displayName: 'Amount', type: 'number', isCheckbox: false },
  {
    name: 'rep_doll_divby_per',
    displayName: 'Rep $/%',
    type: 'number',
    isCheckbox: false,
  },
  { name: 'notes', displayName: 'Notes', type: 'string', isCheckbox: false },
  {
    name: 'rep1_name',
    displayName: 'Rep 1',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'rep2_name',
    displayName: 'Rep 2',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'sys_size',
    displayName: 'Sys Size',
    type: 'string',
    isCheckbox: false,
  },
  { name: 'state', displayName: 'State', type: 'string', isCheckbox: false },
  {
    name: 'rep_count',
    displayName: 'Rep Count',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'per_rep_addr_share',
    displayName: 'Per Rep Addr',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'per_rep_def_ovrd',
    displayName: 'Per Rep Def Ovrd',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'per_rep_ovrd_share',
    displayName: 'Per Rep Ovrd',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'rep1_def_resp',
    displayName: 'Rep1 Def Resp',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'r1_pay_scale',
    displayName: 'R1 Pay Scale',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'r1_rebate_credit',
    displayName: 'R1 Rebate Credit $',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'r1_rebate_credit_perc',
    displayName: 'R1 Rebate Credit %',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'r1_addr_resp',
    displayName: 'R1 Addr Resp',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'r2_pay_scale',
    displayName: 'R2 Pay Scale',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'r2_rebate_credit',
    displayName: 'R2 Rebate Credit $',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'r2_rebate_credit_perc',
    displayName: 'R2 Rebate Credit %',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'rep2_def_resp',
    displayName: 'Rep2 Def Resp',
    type: 'string',
    isCheckbox: false,
  },
  {
    name: 'r2_addr_resp',
    displayName: 'R2 Addr Resp',
    type: 'string',
    isCheckbox: false,
  },
  { name: 'start_date', displayName: 'Date', type: 'date', isCheckbox: false },
];
